import React, { useState, useEffect } from 'react';
import { Row, Col, DatePicker, Button } from 'antd';
import moment from 'moment';
import { useHistory } from "react-router";
import { useWindowContext, useGlobalContext } from "../../lib/storage";

const CalenderView = (props) => {
  console.log(props, "----->props");
  const { windowStore, setWindowStore } = useWindowContext();
  const windowDefinition = { ...windowStore.windowDefinition };
  const history = useHistory();
  let identiFiersData = props.identiFiers;
  let laneDatas = props.laneData;
  let laneJsonObj = {};
  let lanePureData = [];
  for (let key in identiFiersData) {
    for (let keys in laneDatas) {
      if (laneDatas[keys] === identiFiersData[key]["key"]) {
        laneJsonObj[identiFiersData[key]["key"]] = identiFiersData[key]["value"];
      }
    }
  }

  props.cardData.filter((data) => {
    for (let key in data) {
      lanePureData.push(data[key]);
    }
    return 0;
  });

  const [selectedDate, setSelectedDate] = useState(moment());
  const [view, setView] = useState('month'); // Initial view

  useEffect(() => {
    setSelectedDate(moment()); // Set selected date to current date on component mount
  }, []);

  const onChange = (date) => {
    setSelectedDate(date);
  };

  const handleViewChange = (value) => {
    setView(value);
  };

  // Function to generate events for each date
  const generateEventsForDate = (date) => {
    const formattedDate = date.format('YYYY-MM-DD HH:mm:ss');
    const eventsForDate = props.cardData.filter(card => {
      return Object.keys(card).some(key => key.startsWith(formattedDate.slice(0, 10)));
    }).flatMap(card => {
      const dateKey = Object.keys(card).find(key => key.startsWith(formattedDate.slice(0, 10)));
      return dateKey ? card[dateKey].map(record => ({
        title: record.recordTitlesData[0]?.titleName || 'No Title',
        time: moment(dateKey).format('HH:mm'),
        recordId:record.recordId
      })) : [];
    });

    return eventsForDate;
  };

  const findLatestMonthWithEvents = (cardData) => {
    let latestMonth = null;
    let latestYear = null;
  
    cardData.forEach(card => {
      Object.keys(card).forEach(dateKey => {
        const date = moment(dateKey, 'YYYY-MM-DD HH:mm:ss');
        const month = date.month();
        const year = date.year();
  
        if (!latestYear || year > latestYear || (year === latestYear && month > latestMonth)) {
          latestMonth = month;
          latestYear = year;
        }
      });
    });
  
    return { latestMonth, latestYear };
  };
  

  // Render function for each date cell
  const renderDateCell = (date) => {
    const events = generateEventsForDate(date);
    const isCurrentDate = date.isSame(moment(), 'day'); // Check if date is current date
    const isRandomEventDay = date.day() === 1 || date.day() === 4; // Randomly select days (Monday or Thursday)
  
    // Define styles for the current date
    const currentDateStyle = isCurrentDate ? { borderRadius: '60%', backgroundColor: '#E2E7F8' } : {};
  
    // Check if there are no events for the current date
    if (events.length === 0) {
      const { latestMonth, latestYear } = findLatestMonthWithEvents(props.cardData);
      if (latestMonth !== null && latestYear !== null) {
        const latestMonthDate = moment({ year: latestYear, month: latestMonth });
        if (date.isSame(latestMonthDate, 'month')) {
          const latestEvents = generateEventsForDate(latestMonthDate);
          return (
            <div style={{ background: "#fff", border: 'none', margin: '1.5px', display: 'flex', flexDirection: 'column', height: "20vh" }}>
              <div style={{ padding: '5px', fontFamily: "Inter", fontSize: "12px", fontWeight: 600, display: 'flex', justifyContent: 'space-between' }}>
                <p style={{ padding: 3, ...currentDateStyle, color: "#192228", opacity: 0.6 }}>{date.format('D')}</p>
                <p style={{ margin: 0, color: "#192228", opacity: 0.6 }}>{date.format('ddd')}</p>
              </div>
              <style>
                {`
                div::-webkit-scrollbar {
                  width: 8px;
                  height: 8px;
                }
                div::-webkit-scrollbar-track {
                  background-color: #f1f1f1;
                }
                div::-webkit-scrollbar-thumb {
                  background-color: #C1C1C1;
                  border-radius: 4px;
                  min-width: 8px;
                  max-width: 8px;
                  min-height: 8px;
                  max-height: 8px;
                }
                div::-webkit-scrollbar-thumb:hover {
                  background-color: #7D7D7D;
                }
                `}
              </style>
              <div style={{ flex: 1, overflowY: 'auto' }}>
                {latestEvents.map((event, index) => (
                  <div onClick={()=>{history.push(`/window/${windowDefinition.ad_window_id}/${event.recordId}`)}} key={index} style={{ marginBottom: "1vh", height: "3vh", color: "#192228", fontFamily: "Inter", fontSize: "12px", fontWeight: 400, padding: "0 0.5em", display: 'flex', justifyContent: 'space-between', borderLeft: '3px solid #276EFA', borderTopLeftRadius: '3px', borderBottomLeftRadius: '3px', cursor:"pointer" }}>
                    <span className='formRecordTitle1' style={{ fontFamily: "Inter", fontSize: "12px" }}>{event.title}</span>
                    <span style={{ fontFamily: "Inter", fontSize: "12px" }}>{event.time}</span>
                  </div>
                ))}
              </div>
            </div>
          );
        }
      }
    }
  
    return (
      <div style={{ background: "#fff", border: 'none', margin: '1.5px', display: 'flex', flexDirection: 'column', height: "20vh" }}>
        <div style={{ padding: '5px', fontFamily: "Inter", fontSize: "12px", fontWeight: 600, display: 'flex', justifyContent: 'space-between' }}>
          <p style={{ padding: 3, ...currentDateStyle, color: "#192228", opacity: 0.6 }}>{date.format('D')}</p>
          <p style={{ margin: 0, color: "#192228", opacity: 0.6 }}>{date.format('ddd')}</p>
        </div>
        <style>
          {`
          div::-webkit-scrollbar {
            width: 8px;
            height: 8px;
          }
          div::-webkit-scrollbar-track {
            background-color: #f1f1f1;
          }
          div::-webkit-scrollbar-thumb {
            background-color: #C1C1C1;
            border-radius: 4px;
            min-width: 8px;
            max-width: 8px;
            min-height: 8px;
            max-height: 8px;
          }
          div::-webkit-scrollbar-thumb:hover {
            background-color: #7D7D7D;
          }
          `}
        </style>
        <div style={{ flex: 1, overflowY: 'auto' }}>
          {events.map((event, index) => (
            <div onClick={()=>{history.push(`/window/${windowDefinition.ad_window_id}/${event.recordId}`)}} key={index} style={{ marginBottom: "1vh", height: "3vh", color: "#192228", fontFamily: "Inter", fontSize: "12px", fontWeight: 400, padding: "0 0.5em", display: 'flex', justifyContent: 'space-between', borderLeft: '3px solid #276EFA', borderTopLeftRadius: '3px', borderBottomLeftRadius: '3px', cursor:"pointer" }}>
              <span className='formRecordTitle1' style={{ fontFamily: "Inter", fontSize: "12px" }}>{event.title}</span>
              <span style={{ fontFamily: "Inter", fontSize: "12px" }}>{event.time}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  

  // Render function for the current view
  const renderView = () => {
    switch (view) {
      case 'day':
        return (
          <div>
            {/* Day view content */}
            <p>Selected Date: {selectedDate.format('YYYY-MM-DD')}</p>
            {renderDateCell(selectedDate)}
          </div>
        );
      case 'week':
        // Generate dates for the week
        const startDate = selectedDate.clone().startOf('week');
        const dates = [...Array(7)].map((_, index) => startDate.clone().add(index, 'days'));
        return (
          <div>
            {/* Week view content */}
            {dates.map((date, index) => (
              <div key={index}>{renderDateCell(date)}</div>
            ))}
          </div>
        );
      case 'month':
        // Generate dates for the month starting from Monday
        const startOfMonth = selectedDate.clone().startOf('month').startOf('week'); // Start from the first Monday of the month
        const endOfMonth = selectedDate.clone().endOf('month').endOf('week');
        const monthDates = [];
        let currentDate = startOfMonth.clone();

        // Loop through each day of the month
        while (currentDate.isSameOrBefore(endOfMonth)) {
          monthDates.push(currentDate.clone());
          currentDate.add(1, 'day');
        }

        // Ensure each row contains exactly seven boxes
        const rows = [];
        for (let i = 0; i < monthDates.length; i += 7) {
          const row = monthDates.slice(i, i + 7);
          // Pad the row with null values if it has fewer than seven dates
          while (row.length < 7) {
            row.push(null);
          }
          rows.push(row);
        }

        return (
          <div>
            {/* Month view content */}
            {rows.map((row, rowIndex) => (
              <div key={rowIndex} style={{ display: 'flex', justifyContent: 'center' }}>
                {row.map((date, index) => (
                  <div key={index} style={{ width: '14%', textAlign: 'center' }}>
                    {date ? renderDateCell(date) : null}
                  </div>
                ))}
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div >
      <Row gutter={16}>
        <Col span={6}>
          <DatePicker onChange={onChange} />
        </Col>
        <Col span={4}>
          <div style={{ textAlign: 'center', color: '#192228', fontFamily: 'Inter', fontSize: '24px', fontWeight: 600 }}>
            {selectedDate.format('MMMM YYYY')}
          </div>
        </Col>
        <Col span={6}>
          <Button.Group style={{ background: "#2F3856", borderRadius: "20px", fontFamily: "Inter", fontSize: "11px", fontWeight: 500, height: "5vh", padding: "7px" }}>
            <Button
              style={{
                background: view === 'day' ? '#fff' : '#2F3856',
                color: view === 'day' ? '#2F3856' : '#fff',
                borderRadius: view === 'day' ? '20px' : "",
                border: "none",
                height: "3vh",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => handleViewChange('day')}
            >
              Day
            </Button>
            <Button
              style={{
                background: view === 'week' ? '#fff' : '#2F3856',
                color: view === 'week' ? '#2F3856' : '#fff',
                borderRadius: view === 'week' ? '20px' : "",
                border: "none",
                height: "3vh",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => handleViewChange('week')}
            >
              Week
            </Button>
            <Button
              style={{
                background: view === 'month' ? '#fff' : '#2F3856',
                color: view === 'month' ? '#2F3856' : '#fff',
                borderRadius: view === 'month' ? '20px' : "",
                // borderBottomRightRadius: '20px',
                border: "none",
                height: "3vh",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => handleViewChange('month')}
            >
              Month
            </Button>
          </Button.Group>
        </Col>
      </Row>
      <div style={{ height: "80vh", overflowY: "scroll",paddingBottom:"9vh" }}>{renderView()}</div>
    </div>
  );
};

export default CalenderView;
