import React, { useEffect, useState, useRef } from "react";
import { Layout, Spin, Tabs, Menu ,message,Row } from "antd";
import { LoadingOutlined,DoubleLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { useGlobalContext } from "../../lib/storage";
import NavBar from "../navBar";
import SideMenu from "../sideMenu";
import ErrorBoundary from "../errorBoundary";
import "antd/dist/antd.css";
import "./index.css";
import Scrollbars from "react-custom-scrollbars";
import SingleNav from "../navBar/singleNav";
import SideNav from "../sideMenu/sideNav";

const { Header, Content } = Layout;
const { TabPane } = Tabs;
const { SubMenu } = Menu;

const AdminLayout = (props) => {
  const { globalStore, setGlobalStore } = useGlobalContext();
  const { userPreferences, windowTabs, userData } = globalStore;
  const Themes = userData.CW360_V2_UI;
  const subMenuData = JSON.parse(localStorage.getItem("subMenuData"))
  const [loading, setLoading] = useState(true);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [iframeUrl,setIframeUrl] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [showExpandedMenu, setShowExpandedMenu] = useState(false); 
  const [menuToggle, setMenuToggle] = useState(false);
  const [fullMenuToggle, setFullMenuToggle] = useState(true);
  const [showToggler,setShowToggler] = useState(false)
  const [drawerFlag, setDrawerFlag] = useState(false);
  const history = useHistory();
  const extractSubMenuKeys = (data) => {
    if (!data) return [];
    return data.flatMap(item => {
      const keys = [item.key];
      if (item.children && item.children.length > 0) {
        keys.push(...extractSubMenuKeys(item.children));
      }
      return keys;
    });
  };

  const [openKeys, setOpenKeys] = useState("");
  // console.log(openKeys)

  useEffect(() => {
    const newOpenKeys = subMenuData?.map((menu) => menu.key);
    // Only update openKeys if it has changed to avoid an infinite loop
    if (JSON.stringify(openKeys) !== JSON.stringify(newOpenKeys)) {
      setOpenKeys(newOpenKeys);
    }
  }, [subMenuData]);
  let sideNav = userData.CW360_V2_UI.appTheme.side_menu;
  let lowerSubMenuItem = localStorage.getItem("lowerSubMenuItem")
  let subMenuItem = JSON.parse(localStorage.getItem("subMenuItem"))
  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "#c1c1c1",
      borderRadius: "5px",
      width: "8px",
      height: "100px",
      transition: "background-color 0.3s" // Add transition for smooth effect
    };
  
    const hoverStyle = {
      backgroundColor: "#888888" // Darker background color on hover
    };
  
    return (
      <div
        style={{ ...style, ...thumbStyle }}
        {...props}
        onMouseEnter={(e) => {
          e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = thumbStyle.backgroundColor;
        }}
      />
    );
  };
  

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      color: "#00000",
    };
    return <div className="box" style={{ ...style, ...viewStyle,marginBottom:windowType==="Dashboard"&&!sideNav ?"7em":"" }} {...props} />;
  };

  useEffect(() => {
    setTimeout(() => setLoading(false), 200);
  }, []);

  const [activeKey, setActiveKey] = useState(0);
  const onChangeKey = (key) => {
    history.push(windowTabs[key].url);
    setActiveKey(key);
  };

  const initialExc = useRef(false);
  useEffect(() => {
    if (initialExc.current) {
      if (userPreferences.enableMultiTab === "Y" && windowTabs.length > 0) {
        if (windowTabs[parseInt(activeKey)].content === null) {
          const localwindowTabs = [...windowTabs];
          localwindowTabs[parseInt(activeKey)].content = props.children;
          setGlobalStore({ windowTabs: [...localwindowTabs] });
        }
      }
    }
  }, [activeKey]);

  const editTab = (tabKey, action) => {
    if (action === "remove") {
      removeTab(tabKey);
    }
  };

  const removeTab = (key) => {
    const localwindowTabs = [...windowTabs];
    const tabKey = parseInt(key);
    localwindowTabs.splice(tabKey, 1);
    setGlobalStore({ windowTabs: [...localwindowTabs] });
    let newKey = 0;
    if (tabKey >= 1) {
      newKey = tabKey - 1;
    }
    setActiveKey(`${newKey}`);
    history.push(windowTabs[newKey].url);
  };

  useEffect(() => {
    if (userPreferences?.enableMultiTab === "Y") {
      if (windowTabs.length > 0) {
        const localwindowTabs = [...windowTabs];
        const tabIdx = localwindowTabs.findIndex((tab) => tab.url === history.location.pathname && tab.content === null);
        if (tabIdx >= 0) {
          localwindowTabs[tabIdx].content = props.children;
          setGlobalStore({ windowTabs: [...localwindowTabs] });
          setActiveKey(`${tabIdx}`);
          const localStorageTabs = [];
          localwindowTabs.map((tab) => {
            const newTabData = { ...tab, content: null };
            return localStorageTabs.push(newTabData);
          });
          localStorage.setItem("windowTabs", JSON.stringify(localStorageTabs));
        }
      } else {
        const newWindowTab = [];
        newWindowTab.push({
          url: history.location.pathname,
          title: "Window",
          content: props.children,
        });
        setGlobalStore({ windowTabs: [...newWindowTab] });
        setActiveKey(`0`);
        newWindowTab[0].content = null;
        localStorage.setItem("windowTabs", JSON.stringify(newWindowTab));
      }
      initialExc.current = true;
    }
  }, [windowTabs]);

  const onClose = () => {
    setDrawerFlag(false);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}
const windowType = localStorage.getItem('windowType')
const innerWidth = windowSize.innerWidth
const WId = (window.location.href).split('/').pop()

const selectMenuToNavigate = (data) => {
  setIframeUrl(null)
  localStorage.setItem("lowerSubMenuItem", data.key);
  if (data.type === "Report") {
    localStorage.setItem("windowType","Report")
    history.push(`/reports/report/${data.id}`);
  } else if (data.type === "Dashboard") {
    localStorage.setItem("windowType","Dashboard")
    history.push(`/analytics/dashboard/${data.id}`);
  } else if (data.type === "Generic") {
    localStorage.setItem('windowType',"Generic")
    history.push(`/window/list/${data.id}`);
  } else if (data.type === "Custom") {
    history.push(`/others/window/${data.id}`);
  } else if (data.type === "External" ) {
    if(data.navigation === "NewTab"){
      window.open(`${data.url}`)
    }else if (data.navigation === "EmbeddedView"){
      setIframeUrl(data.url)
    }else if (data.navigation === "popopWindow"){
      const popupUrl = data.url; 
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const popupWidth = 600; // Set the width of the popup window
    const popupHeight = 400; // Set the height of the popup window
    const leftPosition = (screenWidth - popupWidth) / 2;
    const topPosition = (screenHeight - popupHeight) / 2;
    // Define other options for the popup window
    const popupOptions = `
      width=${popupWidth},
      height=${popupHeight},
      top=${topPosition},
      left=${leftPosition},
      resizable=yes,
      scrollbars=yes,
      status=no,
      toolbar=no,
      menubar=no,
      location=no `;
    window.open(popupUrl, "popupWindow", popupOptions);
    }
  } else {
    message.warning("Not Available");
  } 
};

const subMenuNavigate = (data) => {
  console.log(data,"-----------")
  setIframeUrl(null)
  if (data.children === undefined || data.children === null) {
    localStorage.setItem("subMenuItem", JSON.stringify(data.key))
    localStorage.setItem("lowerSubMenu",null)
    localStorage.setItem("windowType",data.type)
    localStorage.setItem("lowerSubMenuItem", null)

    // setMenuToggle(true)
    // setFullMenuToggle(true)
    if (data.type === "Report") {
      history.push(`/reports/report/${data.id}`);
    } else if (data.type === "Dashboard") {
      history.push(`/analytics/dashboard/${data.id}`);
    } else if (data.type === "Generic") {
      history.push(`/window/list/${data.id}`);
    } else if (data.type === "Custom") {
      history.push(`/others/window/${data.id}`);
    }
  }
  else {
    // setMenuToggle(false)
    localStorage.setItem("lowerSubMenu", JSON.stringify(data.children))
    localStorage.setItem("lowerSubMenuItem", JSON.stringify(data.children[0].key))
    localStorage.setItem("subMenuItem", JSON.stringify(data.key))
    localStorage.setItem("windowType",data.type)
    if (data.children[0].type === "Report") {
      localStorage.setItem("windowType","Report")
      history.push(`/reports/report/${data.children[0].id}`);
    } else if (data.children[0].type === "Dashboard") {
      localStorage.setItem("windowType","Dashboard")
      history.push(`/analytics/dashboard/${data.children[0].id}`);
    } else if (data.children[0].type === "Generic") {
      history.push(`/window/list/${data.children[0].id}`);
    } else if (data.children[0].type === "Custom") {
      history.push(`/others/window/${data.children[0].id}`);
    }
  }
};

const renderItem = (item) => {
  if (item.children && item.children.length > 0) {
    return (
      <SubMenu key={item.key} title={item.title}>
        {item.children.map(child => renderItem(child))}
      </SubMenu>
    );
  } else {
    return <Menu.Item key={item.key}>{item.title}</Menu.Item>;
  }
};


  return (
    <Spin indicator={<LoadingOutlined className="spinLoader" style={Themes} spin />} spinning={loading}>
       {sideNav?
      <Layout style={{  fontFamily: "'Open Sans', sans-serif",background:"#f3f4f9",height:"100vh"}}>
        <SideNav collapsed= {collapsed} setCollapsed={setCollapsed} showToggler={showToggler} setShowToggler={setShowToggler} showExpandedMenu={showExpandedMenu} setShowExpandedMenu={setShowExpandedMenu} setMenuToggle={setMenuToggle} menuToggle={menuToggle} drawerFlag={drawerFlag} onClose={onClose} setIframeUrl={setIframeUrl}/>
        <Layout style={{background:"#f3f4f9",flex:"1"}}>
        <Header  style={{backgroundColor:'#FFFFFF',padding:0,height:"auto",position:'sticky',zIndex:100,left:0,right:0,top:0}}>
          {/* <NavBar showToggler={showToggler} setShowToggler ={setShowToggler} fullMenuToggle={fullMenuToggle} setFullMenuToggle={setFullMenuToggle}  menuToggle={menuToggle} setDrawerFlag={setDrawerFlag} drawerFlag={drawerFlag} setMenuToggle={setMenuToggle} setIframeUrl={setIframeUrl}/> */}
          <SingleNav showToggler={showToggler} setShowToggler ={setShowToggler} fullMenuToggle={fullMenuToggle} setFullMenuToggle={setFullMenuToggle}  menuToggle={menuToggle} setDrawerFlag={setDrawerFlag} drawerFlag={drawerFlag} setMenuToggle={setMenuToggle} setIframeUrl={setIframeUrl} collapsed={collapsed} setCollapsed={setCollapsed}/>
        </Header>
        {/* <Layout style={{backgroundColor:windowType==="Dashboard" ||windowType==="Report" || WId === "1001" ? Themes?.appTheme.analyticsBackgroundColor: Themes?.appTheme.primaryBackgroundColor,display:iframeUrl !== null?"none":""}}> */}

        {windowType !== "Dashboard" && windowType !=="Report"  ? 
          <Content className={`${menuToggle?"mainLayoutPadding1":"mainPadding"}`}>
            <Row style={{width:"100%",height:collapsed?"":"100%"}}>
            <div style={{ width: collapsed && subMenuData !== undefined && subMenuData !== null && subMenuData.length !== 0? '0' : '12em', overflow: 'hidden', transition: 'width 0.3s',display:innerWidth>768 && subMenuData.length >0 ?"block":"none",paddingTop:"0.7em",backgroundColor:"#fff" }}>
            
            <DoubleLeftOutlined style={{paddingRight:"1em", transition: 'all 0.3s',float:"right"}} onClick={e => { e.stopPropagation(); setCollapsed(!collapsed)}}  />
            {subMenuData !== undefined && subMenuData !== null && subMenuData.length !== 0 ? (
          <Scrollbars
          style={{
            width: "100%",
            height: "100%",
            transition: 'all 0.3s',
          }}
          hidden={false}
          hideTracksWhenNotNeeded={true}
          universal
          thumbSize={90}
          renderView={renderView}
          renderThumbHorizontal={renderThumb}
          renderThumbVertical={renderThumb}
        >
            <Menu selectedKeys={lowerSubMenuItem} className="lineMenu" openKeys={openKeys} style={{backgroundColor:"white",fontFamily:"Inter",color:"#0C173A",padding:"0 1em"}} mode="inline" >
              {subMenuData.map((menu, index) =>
                menu.children && menu.children.length > 0 ? (
                  <SubMenu
                    key={menu.key} // Ensure each SubMenu has a unique key
                    style={{background:"#fff"}}
                    title={
                      <span title={menu.title} style={{fontSize:"11px",display:"flex",justifyContent: "space-between", alignItems: "center"}}>
                        {menu.title}
                      </span>
                    }
                    className="lineMenu"
                  >
                    {menu.children.map((subMenuItem, ind) => (
                      subMenuItem.children && subMenuItem.children.length > 0 ? (
                        <Menu.ItemGroup  className="lineMenu"  key={subMenuItem.key}  title={ <span title={subMenuItem.title} style={{fontSize:"13px",color:"#0A0213",fontWeight:600,display:"flex",justifyContent: "space-between", alignItems: "center"}}>
                        {subMenuItem.title}
                      </span>}>
                          {subMenuItem.children.map((childMenuItem, childIndex) => (
                            <Menu.Item
                            className="lineMenu"
                              key={childMenuItem.key} // Ensure each MenuItem has a unique key
                              style={{ width: 'auto', paddingLeft: '10px' }}
                              // onClick={() => handleChildMenuItemClick(childMenuItem)}
                            >
                              <span style={{fontSize:"13",fontWeight:200}} title={childMenuItem.title}  >
                                {childMenuItem.title}
                              </span>
                            </Menu.Item>
                          ))}
                        </Menu.ItemGroup>
                      ) : (
                        <Menu.Item
                        className="lineMenu"
                          key={subMenuItem.key} // Ensure each MenuItem has a unique key
                          style={{ width: 'auto', paddingLeft: '10px' }}
                          onClick={() => selectMenuToNavigate({ type: subMenuItem.type, id: subMenuItem.id, key: subMenuItem.key, title: subMenuItem.title, index: index })}
                        >
                          <span style={{}} title={subMenuItem.title}  >
                            {subMenuItem.title}
                          </span>
                        </Menu.Item>
                      )
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item
                  className="lineMenu"
                    key={menu.key} // Ensure each MenuItem has a unique key
                    style={{ width: 'auto', paddingLeft: '10px' }}
                    onClick={() => selectMenuToNavigate({ type: menu.type, id: menu.id, key: menu.key, title: menu.title, index: index })}
                  >
                    <span style={{}} title={menu.title}  >
                      {menu.title}
                    </span>
                  </Menu.Item>
                )
              )}
            </Menu>
          </Scrollbars>
        ) : null}
        </div>
            <div style={{ width: !collapsed &&innerWidth>768 && subMenuData !== undefined && subMenuData !== null && subMenuData.length !== 0 ? 'calc(100% - 12em)' : '100%', transition: 'width 0.3s',overflow:"hidden",padding:"1em 0 0 1em" }}>

            {userPreferences?.enableMultiTab === "Y" ? (
              <Tabs hideAdd onChange={onChangeKey} activeKey={activeKey} type="editable-card" onEdit={editTab}>
                {windowTabs.map((tab, index) => (
                  <TabPane forceRender={false} tab={tab.title} key={index} closable={windowTabs.length > 1 ? true : false}>
                    <ErrorBoundary>{tab.content}</ErrorBoundary>
                  </TabPane>
                ))}
              </Tabs>
            ) : (
              <ErrorBoundary>{props.children}</ErrorBoundary>
            )}
          </div>
          {/* </Card> */}
          </Row>
          </Content>:
          windowType === "Dashboard" ?
          <Row style={{width:"100%",height:collapsed?"":"100%"}}>
            <div style={{ width: collapsed && subMenuData !== undefined && subMenuData !== null && subMenuData.length !== 0? '0' : '12em', overflow: 'hidden', transition: 'width 0.3s',display:innerWidth>768 && subMenuData.length >0 ?"block":"none",paddingTop:"0.7em",backgroundColor:"#fff" }}>
            
            <DoubleLeftOutlined style={{paddingRight:"1em", transition: 'all 0.3s',float:"right"}} onClick={e => { e.stopPropagation(); setCollapsed(!collapsed)}}  />
            {subMenuData !== undefined && subMenuData !== null && subMenuData.length !== 0 ? (
          <Scrollbars
          style={{
            width: "100%",
            height: "100%",
            transition: 'all 0.3s',
          }}
          hidden={false}
          hideTracksWhenNotNeeded={true}
          universal
          thumbSize={90}
          renderView={renderView}
          renderThumbHorizontal={renderThumb}
          renderThumbVertical={renderThumb}
        >
            <Menu selectedKeys={lowerSubMenuItem} className="lineMenu" openKeys={openKeys} style={{backgroundColor:"white",fontFamily:"Inter",color:"#0C173A",padding:"0 1em"}} mode="inline" >
              {subMenuData.map((menu, index) =>
                menu.children && menu.children.length > 0 ? (
                  <SubMenu
                    key={menu.key} // Ensure each SubMenu has a unique key
                    style={{background:"#fff"}}
                    title={
                      <span title={menu.title} style={{fontSize:"11px",display:"flex",justifyContent: "space-between", alignItems: "center"}}>
                        {menu.title}
                      </span>
                    }
                    className="lineMenu"
                  >
                    {menu.children.map((subMenuItem, ind) => (
                      subMenuItem.children && subMenuItem.children.length > 0 ? (
                        <Menu.ItemGroup  className="lineMenu"  key={subMenuItem.key}  title={ <span title={subMenuItem.title} style={{fontSize:"13px",color:"#0A0213",fontWeight:600,display:"flex",justifyContent: "space-between", alignItems: "center"}}>
                        {subMenuItem.title}
                      </span>}>
                          {subMenuItem.children.map((childMenuItem, childIndex) => (
                            <Menu.Item
                            className="lineMenu"
                              key={childMenuItem.key} // Ensure each MenuItem has a unique key
                              style={{ width: 'auto', paddingLeft: '10px' }}
                              // onClick={() => handleChildMenuItemClick(childMenuItem)}
                            >
                              <span style={{fontSize:"13",fontWeight:200}} title={childMenuItem.title}  >
                                {childMenuItem.title}
                              </span>
                            </Menu.Item>
                          ))}
                        </Menu.ItemGroup>
                      ) : (
                        <Menu.Item
                        className="lineMenu"
                          key={subMenuItem.key} // Ensure each MenuItem has a unique key
                          style={{ width: 'auto', paddingLeft: '10px' }}
                          onClick={() => selectMenuToNavigate({ type: subMenuItem.type, id: subMenuItem.id, key: subMenuItem.key, title: subMenuItem.title, index: index })}
                        >
                          <span style={{}} title={subMenuItem.title}  >
                            {subMenuItem.title}
                          </span>
                        </Menu.Item>
                      )
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item
                  className="lineMenu"
                    key={menu.key} // Ensure each MenuItem has a unique key
                    style={{ width: 'auto', paddingLeft: '10px' }}
                    onClick={() => selectMenuToNavigate({ type: menu.type, id: menu.id, key: menu.key, title: menu.title, index: index })}
                  >
                    <span style={{}} title={menu.title}  >
                      {menu.title}
                    </span>
                  </Menu.Item>
                )
              )}
            </Menu>
          </Scrollbars>
        ) : null}
        </div>
        <div style={{ width: !collapsed &&innerWidth>768 && subMenuData !== undefined && subMenuData !== null && subMenuData.length !== 0 ? 'calc(100% - 12em)' : '100%', transition: 'width 0.3s',overflow:"hidden" }}>
          <Content className={`${menuToggle?"mainLayoutDashboardPadding1":"mainLayoutDashboardPadding"}`}>
          <div >
            {userPreferences.enableMultiTab === "Y" ? (
              <Tabs hideAdd onChange={onChangeKey} activeKey={activeKey} type="editable-card" onEdit={editTab}>
                {windowTabs.map((tab, index) => (
                  <TabPane forceRender={false} tab={tab.title} key={index} closable={windowTabs.length > 1 ? true : false}>
                    <ErrorBoundary>{tab.content}</ErrorBoundary>
                  </TabPane>
                ))}
              </Tabs>
            ) : (
              <ErrorBoundary>{props.children}</ErrorBoundary>
            )}
          </div>        
          </Content>
          </div>
          </Row> :
          windowType === "Report" ?
          <Row style={{width:"100%",height:collapsed?"":"100%"}}>
          <div style={{ width: collapsed && subMenuData !== undefined && subMenuData !== null && subMenuData.length !== 0? '0' : '17em', overflow: 'hidden', transition: 'width 0.3s',display:innerWidth>768 && subMenuData.length >0 ?"block":"none",paddingTop:"0.7em",backgroundColor:"#fff" }}>
          
          <DoubleLeftOutlined style={{paddingRight:"1em", transition: 'all 0.3s',float:"right"}} onClick={e => { e.stopPropagation(); setCollapsed(!collapsed)}}  />
          {subMenuData !== undefined && subMenuData !== null && subMenuData.length !== 0 ? (
        <Scrollbars
          style={{
            width: "100%",
            height:"100%",
            transition: 'all 0.3s',
          }}
          universal
          hidden={false}
           hideTracksWhenNotNeeded={true}
          thumbSize={90}
          renderView={renderView}
          renderThumbHorizontal={renderThumb}
          renderThumbVertical={renderThumb}
        >
          <Menu selectedKeys={lowerSubMenuItem} className="lineMenu" openKeys={openKeys} style={{backgroundColor:"white",fontFamily:"Inter",color:"#0C173A",padding:"0 1em"}} mode="inline" >
            {subMenuData.map((menu, index) =>
              menu.children && menu.children.length > 0 ? (
                <SubMenu
                  key={menu.key} // Ensure each SubMenu has a unique key
                  style={{background:"#fff"}}
                  title={
                    <span title={menu.title} style={{fontSize:"11px",display:"flex",justifyContent: "space-between", alignItems: "center"}}>
                      {menu.title}
                    </span>
                  }
                  className="lineMenu"
                >
                  {menu.children.map((subMenuItem, ind) => (
                    subMenuItem.children && subMenuItem.children.length > 0 ? (
                      <Menu.ItemGroup  className="lineMenu"  key={subMenuItem.key}  title={ <span title={subMenuItem.title} style={{fontSize:"13px",color:"#0A0213",fontWeight:600,display:"flex",justifyContent: "space-between", alignItems: "center"}}>
                      {subMenuItem.title}
                    </span>}>
                        {subMenuItem.children.map((childMenuItem, childIndex) => (
                          <Menu.Item
                          className="lineMenu"
                            key={childMenuItem.key} // Ensure each MenuItem has a unique key
                            style={{ width: 'auto', paddingLeft: '10px' }}
                            // onClick={() => handleChildMenuItemClick(childMenuItem)}
                          >
                            <span style={{fontSize:"13",fontWeight:200}} title={childMenuItem.title}  >
                              {childMenuItem.title}
                            </span>
                          </Menu.Item>
                        ))}
                      </Menu.ItemGroup>
                    ) : (
                      <Menu.Item
                      className="lineMenu"
                        key={subMenuItem.key} // Ensure each MenuItem has a unique key
                        style={{ width: 'auto', paddingLeft: '10px' }}
                        onClick={() => selectMenuToNavigate({ type: subMenuItem.type, id: subMenuItem.id, key: subMenuItem.key, title: subMenuItem.title, index: index })}
                      >
                        <span style={{}} title={subMenuItem.title}  >
                          {subMenuItem.title}
                        </span>
                      </Menu.Item>
                    )
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item
                className="lineMenu"
                  key={menu.key} // Ensure each MenuItem has a unique key
                  style={{ width: 'auto', paddingLeft: '10px' }}
                  onClick={() => selectMenuToNavigate({ type: menu.type, id: menu.id, key: menu.key, title: menu.title, index: index })}
                >
                  <span style={{}} title={menu.title}  >
                    {menu.title}
                  </span>
                </Menu.Item>
              )
            )}
          </Menu>
        </Scrollbars>
      ) : null}
      </div>
      <div style={{ width: !collapsed &&innerWidth>768 && subMenuData !== undefined && subMenuData !== null && subMenuData.length !== 0 ? 'calc(100% - 17em)' : '100%', transition: 'width 0.3s',overflow:"hidden" }}>
        <Content className={`${menuToggle?"mainLayoutDashboardPadding1":"mainLayoutDashboardPadding"}`}>
        <div >
          {userPreferences.enableMultiTab === "Y" ? (
            <Tabs hideAdd onChange={onChangeKey} activeKey={activeKey} type="editable-card" onEdit={editTab}>
              {windowTabs.map((tab, index) => (
                <TabPane forceRender={false} tab={tab.title} key={index} closable={windowTabs.length > 1 ? true : false}>
                  <ErrorBoundary>{tab.content}</ErrorBoundary>
                </TabPane>
              ))}
            </Tabs>
          ) : (
            <ErrorBoundary>{props.children}</ErrorBoundary>
          )}
        </div>        
        </Content>
        </div>
        </Row>
        :"" }
        {/* </Layout> */}
        </Layout>
        <Layout  style={{display:iframeUrl !== null?"":"none",padding:menuToggle?"1%":"0",background:"#f3f4f9"}}>
        {innerWidth>768?
          <SideMenu showToggler={showToggler} setShowToggler ={setShowToggler} fullMenuToggle={fullMenuToggle} setFullMenuToggle={setFullMenuToggle} setMenuToggle={setMenuToggle} menuToggle={menuToggle} drawerFlag={drawerFlag} onClose={onClose} setIframeUrl={setIframeUrl}/>
        :null}
        <Content className={`${menuToggle?"mainLayoutPadding1":"mainLayoutPadding"}`}>
          {iframeUrl !== null?
        <iframe src={`${iframeUrl}`} id="myIframe" style={{width:"-webkit-fill-available",height:'100vh',border:"none",overflowY:"scroll",display:iframeUrl !== null ?"":"none",paddingBottom:"3vh"}}></iframe>:null}
        </Content>
        </Layout>
      </Layout>:
    <Layout style={{ display: loading ? "none" : "block", fontFamily: "'Open Sans', sans-serif",background:"#f3f4f9",height:"100vh"}}>
      <Header  style={{backgroundColor:'#FFFFFF',padding:0,height:"auto",position:'sticky',zIndex:100,left:0,right:0,top:0}}>
        <NavBar showToggler={showToggler} setShowToggler ={setShowToggler} fullMenuToggle={fullMenuToggle} setFullMenuToggle={setFullMenuToggle}  menuToggle={menuToggle} setDrawerFlag={setDrawerFlag} drawerFlag={drawerFlag} setMenuToggle={setMenuToggle} setIframeUrl={setIframeUrl}/>
      </Header>
      <Layout style={{backgroundColor:windowType==="Dashboard" ||windowType==="Report" || WId === "1001" ? Themes?.appTheme.analyticsBackgroundColor: Themes?.appTheme.primaryBackgroundColor,display:iframeUrl !== null?"none":""}}>
      {innerWidth>768?
        <SideMenu showToggler={showToggler} setShowToggler ={setShowToggler} fullMenuToggle={fullMenuToggle} setFullMenuToggle={setFullMenuToggle} setMenuToggle={setMenuToggle} menuToggle={menuToggle} drawerFlag={drawerFlag} onClose={onClose} setIframeUrl={setIframeUrl}/>
      :null}
      {windowType !== "Dashboard" && windowType !=="Report"  ? 
        <Content className={`${menuToggle?"mainLayoutPadding1":"mainLayoutPadding"}`}>
        {/* <Card  bodyStyle={{padding:"0px",marginTop:"1.4em"}}> */}
        {/* <div style={{backgroundColor:'white',borderRadius:'6px 6px 0px 0px'}}> */}
          {userPreferences?.enableMultiTab === "Y" ? (
            <Tabs hideAdd onChange={onChangeKey} activeKey={activeKey} type="editable-card" onEdit={editTab}>
              {windowTabs.map((tab, index) => (
                <TabPane forceRender={false} tab={tab.title} key={index} closable={windowTabs.length > 1 ? true : false}>
                  <ErrorBoundary>{tab.content}</ErrorBoundary>
                </TabPane>
              ))}
            </Tabs>
          ) : (
            <ErrorBoundary>{props.children}</ErrorBoundary>
          )}
          {/* </div> */}
         {/* </Card> */}
      
    </Content>:
        windowType === "Dashboard" ?
        <Content className={`${menuToggle?"mainLayoutDashboardPadding1":"mainLayoutDashboardPadding"}`}>
        <div >
          {userPreferences.enableMultiTab === "Y" ? (
            <Tabs hideAdd onChange={onChangeKey} activeKey={activeKey} type="editable-card" onEdit={editTab}>
              {windowTabs.map((tab, index) => (
                <TabPane forceRender={false} tab={tab.title} key={index} closable={windowTabs.length > 1 ? true : false}>
                  <ErrorBoundary>{tab.content}</ErrorBoundary>
                </TabPane>
              ))}
            </Tabs>
          ) : (
            <ErrorBoundary>{props.children}</ErrorBoundary>
          )}
         </div>        
    </Content> : windowType === "Report" ?
    <Content className={`${menuToggle?"mainLayoutReportPadding":"mainLayoutReportPadding1"}`}>
    <div >
      {userPreferences.enableMultiTab === "Y" ? (
        <Tabs hideAdd onChange={onChangeKey} activeKey={activeKey} type="editable-card" onEdit={editTab}>
          {windowTabs.map((tab, index) => (
            <TabPane forceRender={false} tab={tab.title} key={index} closable={windowTabs.length > 1 ? true : false}>
              <ErrorBoundary>{tab.content}</ErrorBoundary>
            </TabPane>
          ))}
        </Tabs>
      ) : (
        <ErrorBoundary>{props.children}</ErrorBoundary>
      )}
     </div>        
</Content>:"" }
      </Layout>
      <Layout  style={{display:iframeUrl !== null?"":"none",padding:"1%"}}>
        {iframeUrl !== null?
      <iframe src={`${iframeUrl}`} id="myIframe" style={{height:'82vh',border:"none",overflowY:"scroll",display:iframeUrl !== null ?"":"none"}}></iframe>:null}
      </Layout>
    </Layout>
}
  </Spin>
  );
};

export default AdminLayout;

