import React, { useState } from "react";
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from "@react-google-maps/api";

const markers = [
  {
    id: 1,
    name: "Central Plaza, 123 Main St, Cityville",
    position: { lat: 34.0522, lng: -118.2437 }
  },
  {
    id: 2,
    name: "Northgate Mall, 456 North Rd, Uptown",
    position: { lat: 40.7128, lng: -74.0060 }
  },
  {
    id: 3,
    name: "Lakeside View, 789 Lakeshore Blvd, Lake City",
    position: { lat: 47.6062, lng: -122.3321 }
  },
  {
    id: 4,
    name: "Eastside Complex, 101 East Ave, Springfield",
    position: { lat: 36.1627, lng: -86.7816 }
  },
  {
    id: 5,
    name: "West End Retail, 202 West Ln, Sandiego",
    position: { lat: 32.7157, lng: -117.1611 }
  }
];

function MapView() {
  const [activeMarkers, setActiveMarkers] = useState(markers.map(marker => marker.id));
  
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_APIKey // Add your Google Maps API key here
  });

  const handleToggleMarker = (markerId) => {
    setActiveMarkers((prev) =>
      prev.includes(markerId) ? prev.filter(id => id !== markerId) : [...prev, markerId]
    );
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <GoogleMap
      onLoad={handleOnLoad}
      onClick={() => setActiveMarkers([])}
      mapContainerStyle={{ width: "100vw", height: "100vh" }}
    >
      {markers.map(({ id, name, position }) => (
        <Marker
          key={id}
          position={position}
          onClick={() => handleToggleMarker(id)}
        >
          {activeMarkers.includes(id) && position && (
            <InfoWindow position={position} onCloseClick={() => handleToggleMarker(id)}>
              <div>{name}</div>
            </InfoWindow>
          )}
        </Marker>
      ))}
    </GoogleMap>
  );
}

export default MapView;


