import React, { useState, useEffect } from "react";
import { Row, Col, Layout, Badge, Avatar } from "antd";
import { useGlobalContext } from "../../../lib/storage";
import EmailIcon from "../../../assets/images/envelop.svg";
import CloseIcon from "../../../assets/images/closeIcon.png";
import MeghaPhone from "../../../assets/images/megaphone.svg";
import { getAlerts, getAnnouncements } from "../../../services/generic";
import "./styles.css";

const { Header, Content } = Layout;

const AlertsandMessages = (props) => {
  const { globalStore, setGlobalStore } = useGlobalContext();
  const { userData } = globalStore;
  const Themes = userData.CW360_V2_UI;
  const [loading, setLoading] = useState(true);
  const [menuToggle, setMenuToggle] = useState(false);
  const [fullMenuToggle, setFullMenuToggle] = useState(false);
  const [drawerFlag, setDrawerFlag] = useState(false);
  const [alertsData, setAlertsData] = useState(null);
  const [announcements, setAnnouncements] = useState(null);
  const [selectedAlertTitle, setSelectedAlertTitle] = useState(null);
  const [alertTab, setAlertTab] = useState("inbox");

  useEffect(async () => {
    setTimeout(() => setLoading(false), 200);
    const alertsData = await getAlerts();
    const announcementsData = await getAnnouncements();
    setAnnouncements(announcementsData)
    setAlertsData(alertsData);
  }, []);

  const handleSelectedAlertTitle = (title) => {
    setSelectedAlertTitle(title);
  };

  const handleAlertType = (fieldName) => {
    setAlertTab(fieldName);
  };


  // Group alerts by title
  const groupedAlertsData = alertsData?.reduce((acc, alert) => {
    if (!acc[alert.title]) {
      acc[alert.title] = [];
    }
    acc[alert.title].push(alert);
    return acc;
  }, {});

  const responsiveSearch = { xs: 24, sm: 24, md: 24, lg: 24, xl: 12 };
  const responsivePhone = { xs: 24, sm: 24, md: 24, lg: 24, xl: 2 };
  const responsiveText = { xs: 24, sm: 24, md: 24, lg: 24, xl: 20 };
  const responsiveClose = { xs: 24, sm: 24, md: 24, lg: 24, xl: 2 };

  return (
    <>
    <style>
      {`
        div::-webkit-scrollbar {
          width: 8px;
        }
        div::-webkit-scrollbar-track {
          background-color: #f1f1f1; /* Set the background color of the scrollbar track */
        }
        div::-webkit-scrollbar-thumb {
          background-color: #C1C1C1; /* Set the default background color of the scrollbar thumb */
          border-radius: 4px; /* Set the border radius of the scrollbar thumb */
        }
        div::-webkit-scrollbar-thumb:hover {
          background-color: #7D7D7D; /* Change the background color of the scrollbar thumb on hover */
        }
        /* This part specifically targets the vertical scrollbar thumb */
        div::-webkit-scrollbar-thumb:vertical {
          height: 50px; /* Set the height of the vertical scrollbar thumb */
        }
      `}
    </style>

    <div style={{ height: "92vh",paddingLeft:"1em",overflowY:"scroll" }}>
      <Row gutter={[16, 16]}>
      <Col span={24}>
          <Row gutter={[8, 8]} style={{ display: 'flex', flexWrap: 'wrap' }}>
            {announcements?.map((data) => (
              <Col {...responsiveSearch} key={data.title} style={{ padding: "8px", display: 'flex' }}>
                <div
                  className="card"
                  style={{
                    marginBottom: "5px",
                    borderLeft: `${data.priority === "HP" ? "5px solid #C13832" : `${data.priority === "ST" ? "5px solid #089EA4" : "5px solid #FB9700"}`}`,
                    backgroundColor: "#fff",
                    borderRadius: "6px",
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    flex: 1
                  }}
                >
                  <div style={{ padding: "8px", flex: '1' }}>
                    <Row>
                      <Col {...responsivePhone}>
                        <img src={MeghaPhone} alt="MeghaPhone" style={{ height: "32px", width: "32px" }} />
                      </Col>
                      <Col {...responsiveText}>
                        <h5
                          style={{
                            marginBottom: "3px",
                            color: "#010113",
                            fontWeight: "bold",
                            fontSize: "15px",
                            marginLeft: "3px",
                          }}
                        >
                          {data.title}
                        </h5>
                        <p style={{ marginLeft: "5px", color: "#161417" }}>{data.message}</p>
                      </Col>
                      <Col {...responsiveClose} style={{ textAlign: "right", paddingRight: "10px" }}>
                        <img
                          role="presentation"
                          // onClick={() => deleteAnnouncement(data.csAnnouncementId)}
                          style={{ height: "12px", margin: "0%", marginTop: "0.6rem", cursor: "pointer" }}
                          src={CloseIcon}
                          alt="close"
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Col>

        <Col span={6} style={{ height: "92vh", border: "0.5px solid #DBDBDB",background:"#fff", borderTop: "none" }}>
          <Row>
            <Col span={12}>
              <div className={alertTab === "inbox" ? "alerts-tab-div-active" : "alerts-tabs-div"} onClick={() => handleAlertType("inbox")}>
                <p className={alertTab === "inbox" ? "alerts-tabs-active" : "alerts-tabs"}>Inbox</p>
              </div>
            </Col>
            <Col span={12}>
              <div className={alertTab === "conversations" ? "alerts-tab-div-active" : "alerts-tabs-div"} onClick={() => handleAlertType("conversations")}>
                <p className={alertTab === "conversations" ? "alerts-tabs-active" : "alerts-tabs"}>Tasks</p>
              </div>
            </Col>
          </Row>
          {groupedAlertsData && (
            <div>
              {Object.keys(groupedAlertsData).map((title) => (
                <Row style={{ marginBottom: 10 }} key={title}>
                  <Col span={1}>
                    <div
                      className="selectedItem"
                      style={{
                        visibility: selectedAlertTitle === title ? "visible" : "hidden",
                      }}
                    ></div>
                  </Col>
                  <Col
                    span={20}
                    style={{
                      paddingLeft: 7,
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => handleSelectedAlertTitle(title)}
                  >
                    <div>
                      <p className="alertName">{title}</p>
                    </div>
                  </Col>
                  <Col span={3} style={{ paddingLeft: 7, cursor: "pointer", alignSelf: "center" }}>
                    <img src={EmailIcon} alt="" />
                  </Col>
                </Row>
              ))}
            </div>
          )}
        </Col>

        <Col
          span={18}
          style={{
            height: "92vh",
            padding: "0px 10px 10px",
            backgroundColor: "#F2F3F6",
            borderBottom: "0.5px solid #DBDBDB",
            borderRadius: "3px",
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
          }}
        >
          <div style={{ height: "90.5vh", padding: "15px 20px 20px", backgroundColor: "#ffffff" }}>
            {selectedAlertTitle && groupedAlertsData[selectedAlertTitle] && (
              <div>
                <Row>
                  <Col span={24}>
                    <p className="alertTitle">Alert: {selectedAlertTitle}</p>
                    <hr />
                    {groupedAlertsData[selectedAlertTitle].map((alert, index) => (
                      <p key={index} className="alertDescription">{alert.description}</p>
                    ))}
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
    </>
  );
};

export default AlertsandMessages;
